import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComoAcessarComponent } from './como-acessar/como-acessar.component';
import { PaginaInicialComponent } from './pagina-inicial/pagina-inicial.component';

const routes: Routes = [
  { path: '', component: PaginaInicialComponent },
  { path: 'como-acessar', component: ComoAcessarComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
