<!-- parte inicial -->
<section class="d-flex justify-content-center flex-column text-center container" style="gap:30px;padding: 30px;">

    <div class="image-container">
        <img src="/assets/media/logo.png" class="logo" alt="logotipo">
    </div>

    <h1 class="titulo">Confira como é fácil acessar:</h1>

    <div class="row">
        <div class="col-md-4">
            <img class="img-fluid" src="assets/passos/passo-1.png" alt="" data-aos="zoom-in-down" data-aos-delay="300">
        </div>
        <div class="col-md-4">
            <img class="img-fluid" src="assets/passos/passo-2.png" alt="" data-aos="zoom-in-down" data-aos-delay="400">
        </div>
        <div class="col-md-4">
            <img class="img-fluid" src="assets/passos/passo-3.png" alt="" data-aos="zoom-in-down" data-aos-delay="500">
        </div>
        <div class="col-md-4">
            <img class="img-fluid" src="assets/passos/passo-4.png" alt="" data-aos="zoom-in-down" data-aos-delay="600">
        </div>
        <div class="col-md-4">
            <img class="img-fluid" src="assets/passos/passo-5.png" alt="" data-aos="zoom-in-down" data-aos-delay="700">
        </div>
    </div>

    <div class="d-flex justify-content-center botoes-container">
        <a href="/" class="btn btn-outline-primary botao">Voltar para o site</a>
    </div>

</section>

<footer>
    <img src="/assets/media/logo-efeito.png" alt="easylife-corporate">
</footer>