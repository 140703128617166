import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-como-acessar',
  templateUrl: './como-acessar.component.html',
  styleUrls: ['./como-acessar.component.css']
})
export class ComoAcessarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
